import React, {useEffect, useState} from "react";
import {useApi} from "../services/ApiService";
import {Trans, useTranslation} from "react-i18next";

import PageLayout from "../components/PageLayout";
import SvgIcon from "../components/SvgIcon";
import DownloadButton from "../components/DownloadButton";

export default function Research() {
  const {t} = useTranslation();
  const [error, setError] = useState(false);

  const fileDownload = async (fileName) => {
    try {
      setError(false);
      const url = new URL(window.location.href);
      url.pathname = fileName;

      // Create a link to trigger a file download
      const link = document.createElement('a');
      link.href = url.toString();
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching resources for download:", error);
      setError(true);
    }
  };

  return (
    <PageLayout title="Resources">
      <h1 className="mb-2 text-center">{t("research.title")}</h1>
      <ul className="mt-4 divide-y divide-gray-100 rounded-md border border-gray-200 mx-auto max-w-screen-md">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium items-center inline-flex gap-1">
                {t("research.symposium2023.title")}
                <a className="text-proof-blue hover:underline" href="https://sensoryhealth.org/basic/welcome-to-2023-star-sensory-symposium" target="_blank"><SvgIcon type="link"/></a>
              </span>
              <span className="truncate font-medium inline-flex gap-1 text-sm">
                {t("research.symposium2023.type")}
              </span>
              <span className="text-sm font-light">{t("research.symposium2023.desc")}</span>
            </div>
          </div>
          <div className="ml-2 flex-shrink-0 w-40">
            <div className="w-40 bg-gray-100 flex items-center justify-center">
              <DownloadButton file="/files/PROOF-STARSymposiumPoster.pdf" onClick={fileDownload} isImage={true}>
                <img alt="Symposium Poster" src="/images/PROOF-STARSymposiumPoster-600px.png"/>
              </DownloadButton>
            </div>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium items-center inline-flex gap-1">
                {t("research.otExchange2024.title")}
                <a className="text-proof-blue hover:underline" href="https://www.otausevents.com.au/otexchange2024/" target="_blank"><SvgIcon type="link"/></a>
              </span>
              <span className="truncate font-medium inline-flex gap-1 text-sm">
                {t("research.otExchange2024.type")}
              </span>
              <span className="text-sm font-light">{t("research.otExchange2024.desc")}</span>
            </div>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium items-center inline-flex gap-1">
                {t("research.oregonOt2024.title")}
                <a className="text-proof-blue hover:underline" href="https://www.otao.com/2024-Conference" target="_blank"><SvgIcon type="link"/></a>
              </span>
              <span className="truncate font-medium inline-flex gap-1 text-sm">
                {t("research.oregonOt2024.type")}
              </span>
              <span className="text-sm font-light">{t("research.oregonOt2024.desc")}</span>
            </div>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium items-center inline-flex gap-1">
                {t("research.journal2024.title")}
              </span>
              <span className="text-sm font-light">{t("research.journal2024.desc")}</span>
              <span className="truncate font-medium inline-flex gap-1 text-sm">
                <DownloadButton file="/files/PROOF-SupplementalMaterials-26Oct2024.pdf" onClick={fileDownload}>{t("research.journal2024.type")}</DownloadButton>
              </span>
            </div>
          </div>
        </li>
      </ul>
      {error && (
        <div className="mt-2 text-sm text-center text-red-800">
          <Trans i18nKey="resources.downloadError">
            An error occurred please <a className="font-medium underline hover:opacity-50" href="/resources">reload the
            page</a> and try again
          </Trans>
        </div>)}
    </PageLayout>
  )
}